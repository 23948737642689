import { AxiosError } from 'axios';
import { type GetServerSidePropsContext } from 'next/types';
import { apiClient, type CachedAxiosRequestConfig } from './clients';

export const httpClient = async <Response>(
  config: CachedAxiosRequestConfig,
  req?: GetServerSidePropsContext['req'],
) => {
  return (await apiClient<Response>(config, req)).data;
};

export type ErrorType<Error> = AxiosError<Error>;
