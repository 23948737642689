import { ThemeOptions } from '@mui/material';

import '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    assistive: Palette['primary'];
    critical: Palette['primary'];
    iconDefault: Palette['primary'];
    iconDeactive: Palette['primary'];
    iconInvert: Palette['primary'];
    iconNotif: Palette['primary'];
    surface: Palette['background'];
    divarWidget: Palette['primary'];
  }

  interface PaletteOptions {
    assistive: PaletteOptions['primary'];
    critical: PaletteOptions['primary'];
    iconDefault: PaletteOptions['primary'];
    iconDeactive: PaletteOptions['primary'];
    iconInvert: PaletteOptions['primary'];
    iconNotif: PaletteOptions['primary'];
    surface: PaletteOptions['background'];
    divarWidget: PaletteOptions['primary'];
  }

  interface PaletteColor {
    main: string;
    surface?: string;
    border?: string;
    icon?: string;
  }

  interface SimplePaletteColorOptions {
    main: string;
    surface?: string;
    border?: string;
    icon?: string;
    light?: string;
    dark?: string;
    contrastText?: string;
  }
}

const palette: ThemeOptions['palette'] = {
  text: {
    primary: '#202021',
  },
  common: {
    black: '#212121',
    white: '#ffffff',
  },
  primary: {
    main: '#00A754',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#694FB4',
    border: '#9980E1',
    contrastText: '#ffffff',
    light: '#F3F2F8',
  },
  error: {
    main: '#CD0000',
    surface: '#FFF4F4',
    border: '#FD5749',
    icon: '#DF1D17',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#FA6B00',
    surface: '#FFF1E5',
    border: '#FEA764',
    icon: '#FF7700',
    contrastText: '#212121',
  },
  info: {
    main: '#006ED8',
    surface: '#EBF9FC',
    border: '#6BC2ED',
    icon: '#0078FB',
    contrastText: '#212121',
  },
  success: {
    main: '#2EB974',
    surface: '#EBF8F1',
    border: '#B8EFD4',
    icon: '#5CCB94',
    contrastText: '#212121',
  },
  assistive: {
    main: '#FFCE32',
    contrastText: '#ffffff',
  },
  critical: {
    main: '#CD0000',
    surface: '#FFF4F4',
    border: '#FD5749',
    icon: '#DF1D17',
    contrastText: '#ffffff',
  },
  iconDefault: {
    main: '#6E6E6E',
    contrastText: '#ffffff',
  },
  iconDeactive: {
    main: '#a9a9aa',
    contrastText: '#ffffff',
    light: '#f4f4f4',
  },
  iconInvert: {
    main: '#e5e5e6',
    contrastText: '#212121',
  },
  iconNotif: {
    main: '#f13739',
    contrastText: '#ffffff',
  },
  background: {
    default: '#fafafa',
    paper: '#f2f2f2', // background devider/border
  },
  surface: {
    default: '#ffffff',
    paper: '#ebebeb', // surface main devider/border
  },
  divarWidget: {
    border: '#99322C',
    main: '#99322C',
    surface: '#FFFFFF',
  },
};

export default palette;

// Declare modules to add custom color for components

import '@mui/material';

declare module '@mui/material' {
  interface IconPropsColorOverrides {
    assistive: true;
    critical: true;
    iconDefault: true;
    iconDeactive: true;
    iconInvert: true;
    iconNotif: true;
  }

  interface ButtonPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface TextFieldPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface CheckboxPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface TimelineDotPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface SvgIconPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface SwitchPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface BadgePropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface FormControlPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface FabPropsColorOverrides {
    assistive: true;
    critical: true;
    divar: true;
  }

  interface IconPropsColorOverrides {
    assistive: true;
    critical: true;
    iconDefault: true;
    iconDeactive: true;
    iconInvert: true;
    iconNotif: true;
  }

  interface ButtonGroupPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface IconButtonPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface ToggleButtonPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface ToggleButtonGroupPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface LinkPropsColorOverrides {
    primary: true;
    secondary: true;
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }

  interface ChipPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface FormLabelPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface RadioPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface AlertPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface InputLabelPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface InputLabelPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface LinearProgressPropsColorOverrides {
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }

  interface OutlinedInputPropsColorOverrides {
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }

  interface PaginationPropsColorOverrides {
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }

  interface PaginationItemPropsColorOverrides {
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }

  interface InputBasePropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface InputPropsColorOverrides {
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }

  interface CircularProgressPropsColorOverrides {
    assistive: true;
    critical: true;
  }

  interface SliderPropsColorOverrides {
    error: true;
    warning: true;
    info: true;
    success: true;
    assistive: true;
    critical: true;
  }
}
