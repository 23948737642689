import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import useLogic from 'components/CarSearchTextFeild/useLogic';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { Car, FindCarsServiceNames } from 'services/rest/car';
import { CitiesV1List200DataItem } from 'services/rest/car-inspection/cities-new';
import { VehicleType } from 'states/pages-slices/carInspectionSlice';

type YearType = { id: string; label: string };

export type CarDetailsBottomSheetType =
  | 'city'
  | 'cityAPI'
  | 'brand'
  | 'year'
  | undefined;

export type CityType = { label: string; id: number };

const suggestBrandModels: Record<
  FindCarsServiceNames | 'default',
  { id: string; title: string; value: string }[]
> = {
  inspection: [
    { id: nanoid(), title: 'پژو ۲۰۶', value: 'پژو ۲۰۶' },
    { id: nanoid(), title: 'پژو ۲۰۷', value: 'پژو ۲۰۷' },
    { id: nanoid(), title: 'کوییک', value: 'کوییک' },
    { id: nanoid(), title: 'پراید', value: 'پراید' },
    { id: nanoid(), title: 'رنو', value: 'رنو' },
  ],
  concierge_sell: [
    { id: nanoid(), title: 'پژو ۲۰۶', value: 'پژو ۲۰۶' },
    { id: nanoid(), title: 'پژو ۲۰۷', value: 'پژو ۲۰۷' },
    { id: nanoid(), title: 'جک S5', value: 'جک S5' },
    { id: nanoid(), title: 'دنا', value: 'دنا' },
  ],
  default: [
    { id: nanoid(), title: 'پژو ۲۰۶', value: 'پژو ۲۰۶' },
    { id: nanoid(), title: 'پژو ۲۰۷', value: 'پژو ۲۰۷' },
    { id: nanoid(), title: 'تیبا', value: 'تیبا' },
    { id: nanoid(), title: 'جک S5', value: 'جک S5' },
    { id: nanoid(), title: 'کوییک', value: 'کوییک' },
  ],
  concierge_buy: [],
};

export type Props = {
  contentType: CarDetailsBottomSheetType;
  onItemClicked(selectedItem: string | CitiesV1List200DataItem | Car): void;
  cities?: CityType[] | CitiesV1List200DataItem[];
  years?: YearType[];
  onClose(): void;
  isYearsLoading?: boolean | null;
  serviceName?: FindCarsServiceNames;
  searchCarText?: string;
  shouldPrefetchBrands?: boolean;
  showSuggestedBadgeBrands?: boolean;
  vehicleType?: VehicleType;
  manufacturerType?: string;
};

const SelectCityAPIDrawerContent = ({
  cities,
  onItemClicked,
}: {
  cities: CitiesV1List200DataItem[];
  onItemClicked(selectedItem: CitiesV1List200DataItem): void;
}) => {
  return (
    <Stack
      sx={{
        width: '100vw',
        paddingX: '24px',
        paddingY: '16px',
      }}
    >
      {cities.map((city) => {
        return (
          <Typography
            key={city.pk}
            onClick={() => onItemClicked(city)}
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            sx={{ paddingX: '16px', paddingY: '8px' }}
          >
            {city.name}
          </Typography>
        );
      })}
    </Stack>
  );
};

const SelectCityDrawerContent = ({
  cities,
  onItemClicked,
}: {
  cities: CityType[];
  onItemClicked(selectedItem: string): void;
}) => {
  return (
    <Stack
      sx={{
        width: '100vw',
        paddingX: '24px',
        paddingY: '16px',
      }}
    >
      {cities.map((city) => {
        return (
          <Typography
            key={city.id}
            onClick={() => onItemClicked(city.id.toString())}
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            sx={{ paddingX: '16px', paddingY: '8px' }}
          >
            {city.label}
          </Typography>
        );
      })}
    </Stack>
  );
};

const SelectYearDrawerContent = ({
  years,
  onItemClicked,
  isLoading,
}: {
  isLoading?: boolean | null;
  years: YearType[];
  onItemClicked(selectedItem: string): void;
}) => {
  return (
    <Stack
      sx={{
        width: '100vw',
        paddingX: '24px',
        paddingY: '16px',
      }}
    >
      {isLoading && (
        <Stack
          flexDirection="row"
          height="20vh"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}
      <Stack
        sx={{
          maxHeight: '50vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.primary.main,
            borderRadius: '4px',
          },
        }}
      >
        {years.map((year) => {
          return (
            <Typography
              key={year.id}
              onClick={() => onItemClicked(year.id)}
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              sx={{ paddingX: '16px', paddingY: '8px' }}
            >
              {year.label}
            </Typography>
          );
        })}
      </Stack>
    </Stack>
  );
};

const SelectBrandDrawerContent = ({
  onItemClicked,
  serviceName,
  searchText,
  showSuggestedList = true,
  prefetchBrands = true,
  vehicleType,
  manufacturerType,
}: {
  onItemClicked(selectedItem: string | Car): void;
  serviceName?: FindCarsServiceNames;
  searchText?: string;
  showSuggestedList?: boolean;
  prefetchBrands?: boolean;
  vehicleType?: VehicleType;
  manufacturerType?: string | undefined;
}) => {
  const [isPrefetch, setIsPrefetch] = useState(prefetchBrands);

  // const { data } = useCarServices();
  const searchBrandRef = useRef<HTMLDivElement | null>(null);

  const {
    setCar,
    inputValue,
    setInputValue,
    setInputFocus,
    fetchCarData,
    isFetchCarLoading,
    isFindCarLoading,
    autoCompleteOptions,
  } = useLogic(
    null,
    serviceName,
    undefined,
    vehicleType,
    manufacturerType,
    undefined,
    isPrefetch,
  );
  // useLogic(
  //   null,
  //   serviceName,
  //   isPrefetch,
  //   undefined,
  //   vehicleType,
  //   manufacturerType,
  // );

  useEffect(() => {
    setTimeout(() => {
      searchBrandRef.current?.focus();
    }, 200);
  }, []);

  useEffect(() => {
    if (searchText) setInputValue(searchText);
  }, [searchText, setInputValue]);

  useEffect(() => {
    if (inputValue?.length) setIsPrefetch(false);
  }, [inputValue]);

  return (
    <Stack
      sx={{
        paddingX: '24px',
        paddingY: '16px',
        overflow: 'hidden',
      }}
      gap={1}
    >
      <TextField
        color="secondary"
        disabled={prefetchBrands && !fetchCarData}
        value={inputValue}
        autoFocus
        inputRef={searchBrandRef}
        size="small"
        InputProps={{
          placeholder: 'جستجو کنید...',
          endAdornment:
            isFetchCarLoading || isFindCarLoading ? (
              <Stack>
                <CircularProgress
                  size={20}
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                />
              </Stack>
            ) : !inputValue.length ? (
              <SearchIcon />
            ) : (
              <HighlightOffIcon
                onClick={() => {
                  setInputValue('');
                }}
              />
            ),
        }}
        inputProps={{
          'aria-autocomplete': 'none',
          autoComplete: 'nope',
        }}
        label={
          serviceName === 'inspection'
            ? 'جستجوی خودرو/موتورسیکلت'
            : 'جستجوی خودرو'
        }
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      {showSuggestedList && inputValue.length < 1 ? (
        <>
          <Stack direction="column" gap={1} p={1}>
            <Typography fontSize="11px" fontWeight={600} color="#6E6E6E">
              پرطرفدارترین‌ها
            </Typography>
            <Stack
              direction="row"
              sx={(theme) => ({ flexWrap: 'wrap', gap: theme.spacing(1) })}
            >
              {suggestBrandModels?.[serviceName ?? 'default']?.map(
                ({ id, title }) => (
                  <Chip
                    key={id}
                    onClick={() => {
                      setInputValue(title);
                      searchBrandRef.current?.focus();
                    }}
                    clickable={true}
                    label={title}
                  />
                ),
              )}
            </Stack>
          </Stack>
          <Divider />
        </>
      ) : null}

      {isFetchCarLoading || isFindCarLoading ? (
        <Stack margin="auto" paddingTop="4rem" alignItems="center">
          <CircularProgress color="secondary" size="24px" />
          <Typography
            fontWeight={600}
            marginTop="1.5rem"
            sx={{ color: (th) => th.palette.text.primary }}
          >
            در حال جستجو
          </Typography>
        </Stack>
      ) : autoCompleteOptions?.length ? (
        <Stack
          sx={{
            overflowY: 'auto',
            maxHeight: '70vh',
            scrollbarColor: (theme) => theme.palette.secondary.main,
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => theme.palette.secondary.main,
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
          }}
        >
          {autoCompleteOptions.map((item) => {
            return (
              <Typography
                sx={{
                  paddingY: '12px',
                  paddingX: '16px',
                }}
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                onClick={() => {
                  setInputFocus(true);
                  if (
                    item.last_level === 'type' ||
                    item.last_level === 'model'
                  ) {
                    setCar(() => {
                      return (
                        autoCompleteOptions.find((option) => {
                          return (
                            option.name_en === item.name_en &&
                            option.last_level === item.last_level &&
                            option.last_level_id === item.last_level_id
                          );
                        }) || null
                      );
                    });
                    onItemClicked(item);
                  } else {
                    setInputValue(item.name);
                  }
                }}
                key={item.name_en}
              >
                {item.name}
              </Typography>
            );
          })}
        </Stack>
      ) : inputValue.length > 2 ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          py={2}
          px={5}
          gap={3}
        >
          <SearchOffIcon
            sx={(theme) => ({
              width: theme.spacing(4),
              height: theme.spacing(4),
              color: theme.palette.iconDeactive.main,
            })}
          />
          <Typography
            lineHeight={(theme) => theme.spacing(3)}
            fontSize="14px"
            fontWeight={400}
            color={(theme) => theme.palette.iconDeactive.main}
          >
            متاسفانه در حال حاضر امکان سرویس‌دهی به این خودرو
            {serviceName === 'inspection' ? '/موتورسیکلت' : ''} وجود ندارد.
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          py={2}
          px={5}
          gap={3}
        >
          <ManageSearchIcon
            sx={(theme) => ({
              width: theme.spacing(4),
              height: theme.spacing(4),
              color: theme.palette.iconDeactive.main,
            })}
          />
          <Typography
            lineHeight={(theme) => theme.spacing(3)}
            fontSize="14px"
            fontWeight={400}
            color={(theme) => theme.palette.iconDeactive.main}
          >
            برای جستجو حداقل ۳ حرف را وارد کنید. مثلا؛ «برل» برای برلیانس
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

const CarDetailsBottomSheet = ({
  contentType,
  onClose,
  cities,
  years,
  isYearsLoading,
  onItemClicked,
  serviceName,
  searchCarText,
  shouldPrefetchBrands = true,
  showSuggestedBadgeBrands = true,
  vehicleType,
  manufacturerType,
}: Props) => {
  const bsTitle =
    contentType === 'city' || contentType === 'cityAPI'
      ? 'انتخاب شهر'
      : contentType === 'brand'
      ? serviceName === 'inspection'
        ? 'انتخاب خودرو/موتورسیکلت'
        : 'انتخاب خودرو'
      : 'سال ساخت';

  const theme = useTheme();

  return (
    <Drawer
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: contentType !== 'brand' ? '28px' : '',
          borderTopRightRadius: contentType !== 'brand' ? '28px' : '',
          backgroundColor: 'white',
          height: contentType === 'brand' ? '100%' : 'fit-content',
          maxHeight: contentType === 'brand' ? '100%' : '60%',
          overflow: 'hidden',
        },
      }}
      keepMounted={false}
      anchor={'bottom'}
      open={!!contentType}
      onClose={onClose}
    >
      <Stack>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingY: '26px',
            paddingX: '16px',
            borderBottom: `1px solid ${theme.palette.surface.paper}`,
          }}
        >
          <CloseIcon onClick={onClose} />
          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
            }}
            fontWeight="600"
            fontSize="16px"
            height="26px"
          >
            {bsTitle}
          </Typography>
        </Stack>
        {contentType === 'city' && (
          <SelectCityDrawerContent
            onItemClicked={onItemClicked}
            cities={
              !!cities ? (cities as CityType[]) : [{ label: 'تهران', id: 0 }]
            }
          />
        )}
        {contentType === 'cityAPI' && (
          <SelectCityAPIDrawerContent
            onItemClicked={onItemClicked}
            cities={!!cities ? (cities as CitiesV1List200DataItem[]) : []}
          />
        )}
        {contentType === 'brand' && (
          <SelectBrandDrawerContent
            onItemClicked={onItemClicked}
            serviceName={serviceName}
            searchText={searchCarText}
            showSuggestedList={showSuggestedBadgeBrands}
            prefetchBrands={shouldPrefetchBrands}
            vehicleType={vehicleType}
            manufacturerType={manufacturerType}
          />
        )}
        {contentType === 'year' && !!years && (
          <SelectYearDrawerContent
            isLoading={isYearsLoading}
            onItemClicked={onItemClicked}
            years={years}
          />
        )}
      </Stack>
    </Drawer>
  );
};
export default CarDetailsBottomSheet;
