import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { useQueryClient } from '@tanstack/react-query';
import CarDetailsBottomSheet, {
  CarDetailsBottomSheetType,
} from 'components/BottomSheets/CarDetailsBottomSheet';
import CarSearchTextFeild from 'components/CarSearchTextFeild';
import Toast from 'components/toast';
import { useGetManufactureYears } from 'containers/car-services-order/requests';
import { useFormik } from 'formik';
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Car } from 'services/rest/car';
import * as yup from 'yup';
import UsageIcon from '../../../../../../public/assets/icons/price-report/usage.svg';
import useFunnelContext from '../../../../../hooks/useFunnelContext';
import {
  useCreateCarProfile,
  useUpdateBodyStatus,
  useUpdateMileage,
} from '../../../../../services/rest/car-exchange';
import { CarProfileDto } from '../../../../../services/rest/car-exchange/carExchange';
import { DynamicListBS } from '../../../components/dynamicListBS';
import { carStatus } from '../../../configs';
import { ModeType } from '../SelectCar';
import styles from './styles';

type FormikValues = {
  manufacturedYear: string;
  usage: string;
  brandModel: string;
  carStatus: string;
};

const AddNewCar = ({
  mode = 'default',
  onChangeMode,
  car,
}: {
  mode?: ModeType;
  onChangeMode: (id: string, selectedMode: ModeType) => void;
  car?: CarProfileDto;
}) => {
  const usageRef = useRef<HTMLInputElement | null>(null);
  const carStatusRef = useRef<HTMLInputElement | null>(null);
  const brandRef = useRef<HTMLDivElement | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<Car | null>(null);
  const [bottomSheetType, setBottomSheetType] =
    useState<CarDetailsBottomSheetType>(undefined);
  const [isBodyBsOpen, setBodyBsOpen] = useState(false);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const {
    mutate: getManufactureYears,
    data: manufactureYears,
    isLoading: isYearsLoading,
  } = useGetManufactureYears({
    onError: () => {
      Toast({
        type: 'error',
        message:
          'متاسفانه خطایی رخ داده است. لطفا مجددا تلاش کنید یا در صورت تکرار مشکل ایجاد شده، با پشتیبانی کارنامه تماس بگیرید.',
      });
    },
  });
  const addCarprofile = useCreateCarProfile();
  const updateMileage = useUpdateMileage();
  const updateBodyStatus = useUpdateBodyStatus();
  const funnelData = useFunnelContext();

  const CarInfoSchema = yup.object().shape({
    manufacturedYear: yup
      .string()
      .required('لطفا سال ساخت خودرو را انتخاب کنید.'),
    brandModel: yup.string().required('لطفا مدل خودرو خود را انتخاب کنید.'),
  });

  const handleAddCar = (values: FormikValues) => {
    if (!selectedBrand) return;
    addCarprofile.mutate(
      {
        brand_model_en: selectedBrand.name_en,
        brand_model_fa: selectedBrand.name,
        manufactured_year: values.manufacturedYear,
        mileage: values.usage === '' ? undefined : values.usage,
        body_status: values.carStatus === '' ? undefined : values.carStatus,
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(['my-cars']);
          onChangeMode(res.data.id, 'carAdded');
        },
      },
    );
  };

  const handleEditCar = async (values: FormikValues) => {
    if (!car) return;
    if (
      car.latest_mileage?.mileage === values.usage &&
      car.body_status?.summarized_status === values.carStatus
    ) {
      onChangeMode(car.id, 'carAdded');
      return;
    }
    try {
      await Promise.allSettled([
        updateBodyStatus.mutateAsync({
          id: car.id,
          summarized_status: values.carStatus,
        }),
        updateMileage.mutateAsync({
          id: car.id,
          mileage: values.usage,
        }),
      ]);

      await queryClient.invalidateQueries(['my-cars']);
      onChangeMode(car.id, 'carAdded');
    } catch (error) {
      console.error({ error });
    }
  };

  const formik = useFormik({
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      manufacturedYear: car ? car.manufactured_year : '',
      usage: car && car.latest_mileage ? car.latest_mileage.mileage : '',
      brandModel: car ? car.brand_model_fa : '',
      carStatus:
        car && car.body_status ? car.body_status.summarized_status : '',
    },
    validationSchema: CarInfoSchema,
    onSubmit: (values) => {
      if (mode === 'edit') handleEditCar(values);
      else handleAddCar(values);
    },
  });

  const bottomSheetResultHandler = (item: string | Car) => {
    switch (bottomSheetType) {
      case 'brand':
        formik.setFieldValue('brandModel', (item as Car).name);
        formik.setFieldValue('manufacturedYear', '');
        setSelectedBrand(item as Car);
        setBottomSheetType(undefined);
        brandRef.current?.focus();
        setTimeout(() => {
          setBottomSheetType('year');
        }, 300);
        break;
      case 'year':
        formik.setFieldValue('manufacturedYear', item);
        setTimeout(() => {
          usageRef.current?.focus();
        }, 300);
        setBottomSheetType(undefined);
        break;
      default:
        break;
    }
  };

  const manufacturedList = useMemo(() => {
    return (manufactureYears ?? [])
      .sort((first, second) => (first > second ? -1 : first === second ? 0 : 1))
      .map((yearItem) => {
        return {
          id: String(yearItem),
          label: `${Number(yearItem) + 621} - ${yearItem}`,
        };
      });
  }, [manufactureYears]);

  useEffect(() => {
    if (!selectedBrand?.name_en) return;
    getManufactureYears({ nameEn: selectedBrand?.name_en ?? '' });
  }, [formik.values.brandModel, getManufactureYears, selectedBrand?.name_en]);

  const mobileBrandModel = (
    <Stack sx={{ ...styles.inputContainer, alignItems: 'start' }}>
      <TextField
        disabled={mode === 'edit'}
        error={!!formik.errors.brandModel}
        helperText={formik.errors.brandModel}
        inputRef={brandRef}
        value={formik.values.brandModel}
        color="secondary"
        sx={{
          ...styles.input,
        }}
        size="small"
        onClick={() => {
          if (mode !== 'edit') setBottomSheetType('brand');
        }}
        InputProps={{
          placeholder: 'انتخاب کنید ...',
          readOnly: true,
          startAdornment: (
            <SearchRoundedIcon
              sx={{
                ...styles.startAndormentIcon,
              }}
            />
          ),
        }}
        inputProps={{
          'aria-autocomplete': 'none',
          autoComplete: 'nope',
        }}
        label="انتخاب خودرو"
      />
    </Stack>
  );

  const desktopBrandModel = (
    <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
      <FormControl sx={styles.formControl}>
        <CarSearchTextFeild
          inputTextValue={formik.values.brandModel}
          isDisabled={mode === 'edit'}
          vehicleType="light_car"
          error={formik.errors.brandModel}
          initialValue={selectedBrand}
          size="small"
          secondary={true}
          onChange={(newCar) => {
            setSelectedBrand(newCar);
            formik.setFieldValue('brandModel', newCar?.name);
            formik.setFieldValue('manufacturedYear', '');
          }}
        />
      </FormControl>
    </Stack>
  );

  const findYearLabel = (id: string) => {
    for (const year of manufacturedList) {
      if (year.id === id) return year.label;
    }
  };

  const mobileYear = (
    <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
      <TextField
        disabled={mode === 'edit'}
        error={!!formik.errors.manufacturedYear}
        helperText={formik.errors.manufacturedYear}
        color="secondary"
        value={
          findYearLabel(formik.values.manufacturedYear) ||
          formik.values.manufacturedYear
        }
        sx={{
          ...styles.input,
        }}
        size="small"
        onClick={() => {
          if (mode === 'edit') return;
          if (!selectedBrand?.name) {
            setBottomSheetType('brand');
            return;
          }
          setBottomSheetType('year');
        }}
        InputProps={{
          placeholder: 'انتخاب کنید ...',
          readOnly: true,
          startAdornment: (
            <CalendarTodayIcon
              sx={{
                ...styles.startAndormentIcon,
              }}
            />
          ),
        }}
        inputProps={{
          'aria-autocomplete': 'none',
          autoComplete: 'nope',
        }}
        label="سال ساخت"
      />
    </Stack>
  );

  const desktopYear = (
    <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
      <FormControl sx={styles.formControl}>
        <Autocomplete
          disablePortal
          sx={styles.input}
          noOptionsText={'موردی یافت نشد'}
          size="small"
          options={manufacturedList}
          disabled={!manufacturedList.length}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={mode === 'edit'}
              error={!!formik.errors.manufacturedYear}
              helperText={formik.errors.manufacturedYear}
              color="secondary"
              InputProps={{
                ...params.InputProps,
                placeholder: 'انتخاب کنید...',
                startAdornment: (
                  <CalendarTodayIcon
                    sx={{
                      ...styles.startAndormentIcon,
                    }}
                  />
                ),
              }}
              label="سال ساخت"
            />
          )}
          value={
            manufacturedList.find(
              (i) => i.id === formik.values.manufacturedYear,
            ) || {
              id: formik.values.manufacturedYear,
              label: formik.values.manufacturedYear,
            }
          }
          onChange={(_e, newValue) => {
            formik.setFieldValue('manufacturedYear', newValue?.id || null);
            setTimeout(() => {
              usageRef.current?.focus();
            }, 100);
          }}
        />
      </FormControl>
    </Stack>
  );

  const findCarStatusLabel = (id: string) => {
    for (const status of carStatus) {
      if (status.id === id) return status.label;
    }
  };

  const mobileCarStatus = (
    <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
      <TextField
        inputRef={carStatusRef}
        color="secondary"
        value={findCarStatusLabel(formik.values.carStatus) || ''}
        sx={{
          ...styles.input,
        }}
        size="small"
        onClick={() => {
          setBodyBsOpen(true);
        }}
        InputProps={{
          placeholder: 'انتخاب کنید ...',
          readOnly: true,
          startAdornment: (
            <CarRepairIcon
              sx={{
                ...styles.startAndormentIcon,
              }}
            />
          ),
        }}
        inputProps={{
          'aria-autocomplete': 'none',
          autoComplete: 'nope',
        }}
        label="وضعیت خودرو (اختیاری)"
      />
    </Stack>
  );

  const desktopCarStatus = (
    <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
      <FormControl sx={styles.formControl}>
        <Autocomplete
          sx={styles.input}
          size="small"
          options={carStatus}
          renderInput={(params) => (
            <TextField
              {...params}
              color="secondary"
              InputProps={{
                ...params.InputProps,
                placeholder: 'انتخاب کنید...',
                startAdornment: (
                  <CarRepairIcon
                    sx={{
                      ...styles.startAndormentIcon,
                    }}
                  />
                ),
              }}
              label="وضعیت خودرو (اختیاری)"
            />
          )}
          value={
            carStatus.find((i) => i.id === formik.values.carStatus) || null
          }
          onChange={(_e, newValue) => {
            formik.setFieldValue('carStatus', newValue?.id || null);
            setTimeout(() => {
              usageRef.current?.focus();
            }, 100);
          }}
        />
      </FormControl>
    </Stack>
  );

  const usageTextField = (
    <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
      <TextField
        inputRef={usageRef}
        value={
          formik.values.usage
            ? Number(formik.values.usage).toLocaleString()
            : ''
        }
        onChange={(e) =>
          formik.setFieldValue('usage', e.target.value.replace(/\D/g, ''))
        }
        color="secondary"
        inputMode="numeric"
        sx={{
          ...styles.input,
        }}
        size="small"
        InputProps={{
          placeholder: 'وارد کنید ...',
          inputMode: 'numeric',
          endAdornment: <InputAdornment position="end">کیلومتر</InputAdornment>,
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                marginLeft: '0.3rem',
                marginRight: '0',
              }}
            >
              <Image src={UsageIcon} alt="usage" width="24px" height="24px" />
            </InputAdornment>
          ),
        }}
        label="کارکرد (اختیاری)"
      />
    </Stack>
  );

  return (
    <Stack
      sx={{ width: '100%', height: '100%', justifyContent: 'space-between' }}
    >
      <Stack sx={{ width: '100%' }}>
        <Typography sx={styles.paragraph}>
          مشخصات خودروی موردنظر را مشخص کنید:
        </Typography>
        {matches ? mobileBrandModel : desktopBrandModel}
        {matches ? mobileYear : desktopYear}
        {usageTextField}
        {matches ? mobileCarStatus : desktopCarStatus}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: mode === 'default' ? 'space-between' : 'end',
          width: '100%',
          pt: '1.5rem',
          borderTop: { md: '1px solid #EBEBEB' },
        }}
      >
        {mode === 'default' && (
          <Typography
            onClick={() => funnelData?.changeStep('determining-budget')}
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: 'primary.main',
              cursor: 'pointer',
            }}
          >
            خودرویی ندارم
          </Typography>
        )}
        <Button
          onClick={formik.submitForm}
          disabled={
            mode === 'addCar' &&
            (!Boolean(formik.values.brandModel) ||
              !Boolean(formik.values.manufacturedYear))
          }
          sx={{
            width: { xs: mode === 'default' ? '50%' : '100%', md: '12.5rem' },
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          تایید و ادامه
        </Button>
      </Box>

      <CarDetailsBottomSheet
        onItemClicked={bottomSheetResultHandler}
        onClose={() => setBottomSheetType(undefined)}
        years={manufacturedList}
        isYearsLoading={isYearsLoading}
        contentType={bottomSheetType}
        shouldPrefetchBrands={false}
        showSuggestedBadgeBrands={true}
        vehicleType="light_car"
      />
      <DynamicListBS
        list={carStatus}
        title="وضعیت خودرو"
        isOpen={isBodyBsOpen}
        onClose={() => {
          setBodyBsOpen(false);
        }}
        onItemClicked={(item) => {
          formik.setFieldValue('carStatus', item.id);
          setBodyBsOpen(false);
        }}
      />
    </Stack>
  );
};

export default AddNewCar;
