import { ErrorType, httpClient } from 'services/http-client';
import { booghEndPionts } from 'services/configs';
import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
} from '@tanstack/react-query';

const pushTokenBody = (data: PushTokenParams) => {
  return httpClient<PushTokenResponse>({
    url: booghEndPionts.PUSH_TOKEN,
    method: 'post',
    data,
  });
};

export const usePushToken = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pushTokenBody>>,
    TError,
    { data: PushTokenParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pushTokenBody>>,
    { data: PushTokenParams }
  > = (props) => {
    const { data } = props ?? {};

    return pushTokenBody(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof pushTokenBody>>,
    TError,
    { data: PushTokenParams },
    TContext
  >(mutationFn, mutationOptions);
};
