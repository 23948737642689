import { useEffect } from 'react';
import { useSendUserAction } from 'services/rest/userLogging';

const TRACEABLE_ELEMENT_IS_TRACEABLE_ATTRIBUTE_NAME = '_istraceable';
const TRACEABLE_ELEMENT_SERVICE_NAME_ATTRIBUTE_NAME = '_traceservicename';
const TRACEABLE_ELEMENT_EVENT_NAME_ATTRIBUTE_NAME = '_traceeventname';
const TRACEABLE_ELEMENT_EVENT_TYPE_ATTRIBUTE_NAME = '_traceeventtype';
const TRACEABLE_ELEMENT_EVENT_METADATA_ATTRIBUTE_NAME = '_traceeventmetadata';

const isElement = (elm: EventTarget | null): elm is Element => {
  return Boolean(elm);
};

const usePageClickTracer = () => {
  const { mutate: sendUserAction } = useSendUserAction();

  useEffect(() => {
    const clickEventHandler = (e: MouseEvent) => {
      let targetElm = e.target;
      while (isElement(targetElm) && targetElm !== document.body) {
        const isTraceable =
          ((targetElm.getAttribute(
            TRACEABLE_ELEMENT_IS_TRACEABLE_ATTRIBUTE_NAME,
          ) as 'true' | 'false' | null) || 'false') === 'true';
        if (
          isTraceable &&
          targetElm.hasAttribute(
            TRACEABLE_ELEMENT_SERVICE_NAME_ATTRIBUTE_NAME,
          ) &&
          targetElm.hasAttribute(TRACEABLE_ELEMENT_EVENT_NAME_ATTRIBUTE_NAME)
        ) {
          const serviceNameValue = targetElm.getAttribute(
            TRACEABLE_ELEMENT_SERVICE_NAME_ATTRIBUTE_NAME,
          );
          const eventNameValue = targetElm.getAttribute(
            TRACEABLE_ELEMENT_EVENT_NAME_ATTRIBUTE_NAME,
          );
          const eventTypeValue = targetElm.getAttribute(
            TRACEABLE_ELEMENT_EVENT_TYPE_ATTRIBUTE_NAME,
          );
          const eventMetadataValue = JSON.parse(
            targetElm.getAttribute(
              TRACEABLE_ELEMENT_EVENT_METADATA_ATTRIBUTE_NAME,
            ) || '{}',
          );
          if (
            typeof serviceNameValue === 'string' &&
            typeof eventNameValue === 'string'
          ) {
            sendUserAction({
              event_type:
                (eventTypeValue as SendUserActionParams['event_type'] | null) ||
                'click',
              service_name:
                serviceNameValue as SendUserActionParams['service_name'],
              event_name: eventNameValue as SendUserActionParams['event_name'],
              event_metadata:
                eventMetadataValue as SendUserActionParams['event_metadata'],
            });
            break;
          }
        }
        if (targetElm.parentElement) {
          targetElm = targetElm.parentElement;
        } else {
          targetElm = null;
        }
      }
    };

    window.addEventListener('click', clickEventHandler);

    return () => {
      window.removeEventListener('click', clickEventHandler);
    };
  }, [sendUserAction]);
};

export default usePageClickTracer;
