import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AppNumberInput from 'components/AppNumberInput';
import CarDetailsBottomSheet, {
  CarDetailsBottomSheetType,
} from 'components/BottomSheets/CarDetailsBottomSheet';
import CarSearchTextFeild from 'components/CarSearchTextFeild';
import Toast from 'components/toast';
import Footer from 'containers/car-services-order/components/Footer';
import Tick from 'containers/car-services-order/components/Tick';
import Layout from 'containers/car-services-order/components/layout';
import {
  funnelCityList,
  getRetargetingCarInfo,
  initialData,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  useCreateQuotation,
  useGetManufactureYears,
  useGetQuotation,
  useUpdateQuotation,
} from 'containers/car-services-order/requests';
import { useFormik } from 'formik';
import useFunnelContext from 'hooks/useFunnelContext';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Car } from 'services/rest/car';
import {
  useGetOrderDetails,
  useUpdateOrderMutation,
} from 'services/rest/car-services';
import { OrderStateType } from 'services/rest/car-services/car-services';
import * as yup from 'yup';
import styles from './styles';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const CarInfo: FC<PropTypes> = ({ currentStep, totalStep }) => {
  const funnelData = useFunnelContext();
  const usageRef = useRef<HTMLInputElement | null>(null);
  const brandRef = useRef<HTMLDivElement | null>(null);
  const mileageWrapperRef = useRef<HTMLInputElement>(null);
  const [selectedBrand, setSelectedBrand] = useState<Car | null>(null);
  const [bottomSheetType, setBottomSheetType] =
    useState<CarDetailsBottomSheetType>(undefined);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { data, setData } = useCarServices();
  const { mutate: createQuotation, isLoading: isQuoteLoading } =
    useCreateQuotation();
  const { data: orderData } = useGetOrderDetails(String(data.orderId), {
    enabled: !!data.orderId,
  });
  const { query } = useRouter();
  const {
    mutate: getManufactureYears,
    data: manufactureYears,
    isLoading: isYearsLoading,
  } = useGetManufactureYears({
    onError: () => {
      Toast({
        type: 'error',
        message:
          'متاسفانه خطایی رخ داده است. لطفا مجددا تلاش کنید یا در صورت تکرار مشکل ایجاد شده، با پشتیبانی کارنامه تماس بگیرید.',
      });
    },
  });

  const { data: quotationData } = useGetQuotation(
    { uuid: data.quotationUUID ?? '' },
    { enabled: !data.orderId && !!data.quotationUUID },
  );
  const { mutate: updateQuotation } = useUpdateQuotation();
  const { mutate: updateOrder } = useUpdateOrderMutation();

  const CarInfoSchema = yup.object().shape({
    manufacturedYear: yup
      .string()
      .required('لطفا سال ساخت خودرو را انتخاب کنید.'),
    usage: yup.string().required('لطفا کارکرد خودرو را وارد کنید.'),
    brandModel: yup.string().required('لطفا مدل خودرو خود را انتخاب کنید.'),
  });

  const formik = useFormik({
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      manufacturedYear:
        orderData?.car_profile.manufactured_year ??
        quotationData?.manufactured_year ??
        '',
      usage:
        orderData?.mileage ?? quotationData?.mileage
          ? String(quotationData?.mileage)
          : '',
      brandModel: quotationData?.brand_model_fa ?? '',
    },
    validationSchema: CarInfoSchema,
    onSubmit: (newValues) => {
      if (!selectedBrand?.name_en) return;
      if (
        data.orderId &&
        orderData?.car_profile?.brand_model_en === selectedBrand.name_en
      ) {
        updateOrder(
          {
            id: String(data.orderId),
            manufacture_year: parseInt(newValues.manufacturedYear),
            mileage: parseInt(newValues.usage),
          },
          {
            onSuccess: () => {
              funnelData?.nextStep();
            },
          },
        );
        return;
      }
      if (
        data.quotationUUID &&
        quotationData?.brand_model_en === selectedBrand.name_en
      ) {
        updateQuotation(
          {
            uuid: data.quotationUUID,
            manufactured_year: formik.values.manufacturedYear,
            mileage: parseInt(formik.values.usage),
          },
          {
            onSuccess: () => {
              funnelData?.nextStep();
            },
          },
        );
        return;
      }
      createQuotation(
        {
          brand_model_en: selectedBrand?.name_en,
          brand_model_fa: selectedBrand?.name,
          manufactured_year: parseInt(newValues.manufacturedYear),
          mileage: parseInt(String(newValues.usage)),
        },
        {
          onSuccess: (res) => {
            setData({ orderId: null, quotationUUID: res.data.uuid });
            funnelData?.nextStep();
          },
          onError: () => {},
        },
      );
    },
  });

  const bottomSheetResultHandler = (item: string | Car) => {
    switch (bottomSheetType) {
      case 'brand':
        setData(initialData);
        formik.setFieldValue('brandModel', (item as Car).name);
        formik.setFieldValue('manufacturedYear', '');
        formik.setFieldValue('usage', '');
        setSelectedBrand(item as Car);
        setBottomSheetType(undefined);
        brandRef.current?.focus();
        setTimeout(() => {
          setBottomSheetType('year');
        }, 300);
        break;
      case 'year':
        formik.setFieldValue('manufacturedYear', item);
        requestAnimationFrame(() => {
          usageRef.current?.focus();
        });
        setBottomSheetType(undefined);
        break;
      default:
        break;
    }
  };

  const manufacturedList = useMemo(() => {
    return (manufactureYears ?? [])
      .sort((first, second) => (first > second ? -1 : first === second ? 0 : 1))
      .map((yearItem) => {
        return {
          id: String(yearItem),
          label: `${Number(yearItem) + 621} - ${yearItem}`,
        };
      });
  }, [manufactureYears]);

  useEffect(() => {
    if (!selectedBrand?.name_en) return;
    getManufactureYears({ nameEn: selectedBrand?.name_en ?? '' });
  }, [formik.values.brandModel, getManufactureYears, selectedBrand?.name_en]);

  useEffect(() => {
    if (
      (!data.orderId && !quotationData?.brand_model_en) ||
      (data.orderId && !orderData?.car_profile.brand_model_en)
    )
      return;
    const name =
      (data.orderId
        ? orderData?.car_profile.brand_model_fa
        : quotationData?.brand_model_fa) ?? '';
    formik.setValues({
      brandModel: name,
      manufacturedYear:
        (data.orderId
          ? orderData?.car_profile.manufactured_year
          : quotationData?.manufactured_year) ?? '',
      usage:
        (data.orderId
          ? orderData?.mileage
          : quotationData?.mileage
          ? String(quotationData?.mileage)
          : '') ?? '',
    });

    setSelectedBrand((prev) =>
      name
        ? {
            name,
            name_en:
              (data.orderId
                ? orderData?.car_profile.brand_model_en
                : quotationData?.brand_model_en) ?? '',
            last_level: '',
            last_level_id: -1,
          }
        : prev,
    );
  }, [quotationData, orderData, data.orderId]);

  useEffect(() => {
    const retargetingData = getRetargetingCarInfo(query);
    if (!retargetingData?.car?.name) return;
    setData({ orderId: null, quotationUUID: null });
    formik.setValues({
      brandModel: retargetingData.car.name,
      manufacturedYear: retargetingData?.manufactureYear ?? '',
      usage: retargetingData?.mileage ?? '',
    });
    setSelectedBrand({
      name: retargetingData.car.name,
      name_en: retargetingData.car.name_en,
      last_level: '',
      last_level_id: -1,
    });
  }, [query]);

  useEffect(() => {
    if (
      orderData?.state &&
      (['cancelled', 'done'] as OrderStateType[]).includes(orderData.state)
    ) {
      setData(initialData);
      if (typeof window !== 'undefined')
        location.replace('/services/car-services/car-info');
    }
  }, [orderData?.state, setData]);

  return (
    <Layout
      header={{
        title: 'اطلاعات اولیه',
        totalPage: totalStep,
        currentPage: currentStep,
        prevLink: '/car-services',
      }}
      extraScroll={false}
      isReactiveToResize={true}
    >
      <Typography sx={styles.paragraph}>
        مشخصات خودروی خود را وارد کنید.
      </Typography>
      {matches ? (
        <Stack sx={{ ...styles.inputContainer, alignItems: 'start' }}>
          <TextField
            error={!!formik.errors.brandModel}
            helperText={formik.errors.brandModel}
            inputRef={brandRef}
            value={formik.values.brandModel}
            sx={{
              ...styles.input,
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor: theme.palette.surface.paper,
                },
              },
              '& .MuiOutlinedInput-root': {
                paddingRight: '6px',
                paddingLeft: '6px',
              },
              '& .MuiOutlinedInput-input': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            }}
            size="small"
            onClick={() => {
              setBottomSheetType('brand');
            }}
            InputProps={{
              placeholder: 'جستجو کنید...',
              readOnly: true,
              startAdornment: (
                <SearchRoundedIcon
                  sx={{
                    ...styles.startAndormentIcon,
                    marginRight: '0',
                    marginLeft: 0.5,
                  }}
                />
              ),
            }}
            inputProps={{
              'aria-autocomplete': 'none',
              autoComplete: 'nope',
            }}
            label="انتخاب خودرو"
          />
          <Tick
            sx={{ marginTop: '10px' }}
            isactive={String(Boolean(formik.values.brandModel))}
          />
        </Stack>
      ) : (
        <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
          <FormControl sx={styles.formControl}>
            <CarSearchTextFeild
              vehicleType="light_car"
              error={formik.errors.brandModel}
              initialValue={selectedBrand}
              size="small"
              onChange={(newCar) => {
                setSelectedBrand(newCar);
                setData(initialData);
                formik.setFieldValue('brandModel', newCar?.name);
                formik.setFieldValue('manufacturedYear', '');
                formik.setFieldValue('usage', '');
              }}
              inputTextValue={
                data.orderId
                  ? orderData?.car_profile.brand_model_fa
                  : quotationData?.brand_model_fa
              }
            />
          </FormControl>
          <Tick
            sx={{ marginTop: '10px' }}
            isactive={String(Boolean(!!formik.values.brandModel))}
          />
        </Stack>
      )}
      {matches ? (
        <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
          <TextField
            error={!!formik.errors.manufacturedYear}
            helperText={formik.errors.manufacturedYear}
            value={formik.values.manufacturedYear ?? ''}
            sx={{
              ...styles.input,
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor: theme.palette.surface.paper,
                },
              },
              '& .MuiOutlinedInput-root': {
                paddingRight: '6px',
                paddingLeft: '6px',
              },
              '& .MuiOutlinedInput-input': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            }}
            size="small"
            onClick={() => {
              if (!selectedBrand?.name) {
                setBottomSheetType('brand');
                return;
              }
              setBottomSheetType('year');
            }}
            InputProps={{
              placeholder: 'انتخاب کنید ...',
              readOnly: true,
              startAdornment: (
                <SearchRoundedIcon
                  sx={{
                    ...styles.startAndormentIcon,
                    marginRight: '0',
                    marginLeft: 0.5,
                  }}
                />
              ),
            }}
            inputProps={{
              'aria-autocomplete': 'none',
              autoComplete: 'nope',
            }}
            label="سال ساخت"
          />
          <Tick
            sx={{ marginTop: '10px' }}
            isactive={String(formik.values.manufacturedYear.length > 0)}
          />
        </Stack>
      ) : (
        <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
          <FormControl sx={styles.formControl}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              sx={styles.input}
              noOptionsText={'موردی یافت نشد'}
              size="small"
              options={manufacturedList}
              disabled={!manufacturedList.length}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!formik.errors.manufacturedYear}
                  helperText={formik.errors.manufacturedYear}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: 'انتخاب کنید...',
                    startAdornment: (
                      <SearchRoundedIcon sx={{ color: '#6E6E6E' }} />
                    ),
                  }}
                  label="سال ساخت"
                />
              )}
              value={
                manufacturedList.find(
                  (i) => i.id === formik.values.manufacturedYear,
                ) || null
              }
              onChange={(_e, newValue) => {
                formik.setFieldValue('manufacturedYear', newValue?.id || null);
                setTimeout(() => {
                  usageRef.current?.focus();
                }, 100);
              }}
            />
          </FormControl>
          {isYearsLoading ? (
            <CircularProgress
              sx={{
                width: '22px !important',
                height: '22px !important',
                marginLeft: '8px',
              }}
            />
          ) : (
            <Tick
              sx={{ marginTop: '10px' }}
              isactive={String(Boolean(formik.values.manufacturedYear))}
            />
          )}
        </Stack>
      )}
      <Stack
        component="div"
        sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}
      >
        <AppNumberInput
          name="usage"
          ref={mileageWrapperRef}
          inputRef={usageRef}
          value={formik.values.usage}
          label="کارکرد"
          size="small"
          error={!!formik.errors.usage}
          helperText={formik.errors.usage}
          onFocus={() => {
            // if (matches)
            // requestForAnimation(() => {
            //   usageRef.current?.scrollIntoView();
            // });
            // scrollView(usageRef.current);
            // event.stopPropagation();
          }}
          onClick={() => {
            if (!selectedBrand?.name) {
              usageRef.current?.blur();
              setBottomSheetType('brand');
              return;
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">کیلومتر</InputAdornment>
            ),
            startAdornment: (
              <AvTimerRoundedIcon sx={{ color: '#6E6E6E', marginRight: 1 }} />
            ),
          }}
          sx={{
            ...styles.input,
            '& .MuiInputBase-root.Mui-disabled': {
              '& > fieldset': {
                borderColor: theme.palette.surface.paper,
              },
            },
          }}
          onChange={(e) => {
            formik.setFieldValue('usage', e.target.value);
          }}
        />
        <Tick
          isactive={String(Boolean(formik.values.usage))}
          sx={{ marginTop: '10px' }}
        />
      </Stack>

      <Footer
        id="car-services-car-info-btn"
        onClick={formik.submitForm}
        isLoading={isQuoteLoading}
        locateBaseOnBottom={matches}
      />
      <CarDetailsBottomSheet
        onItemClicked={bottomSheetResultHandler}
        onClose={() => setBottomSheetType(undefined)}
        years={manufacturedList}
        isYearsLoading={isYearsLoading}
        contentType={bottomSheetType}
        cities={funnelCityList}
        shouldPrefetchBrands={false}
        showSuggestedBadgeBrands={true}
        vehicleType="light_car"
      />
    </Layout>
  );
};

export default CarInfo;
