import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Person2Icon from '@mui/icons-material/Person2';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneInTalkOutlined from '@mui/icons-material/PhoneInTalkOutlined';
import { Badge, Button, Link, Menu, MenuItem, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuList from 'components/Menu';
import ENV from 'constants/environments';
import useAppDispatch from 'hooks/useAppDispatch';
import useLogin from 'hooks/useLogin';
import { useRouter } from 'next/router';
import { useMemo, useState, type FC } from 'react';
import { sendUserAction } from 'services/rest/userLogging';
import { logout } from 'states/components-slices/authSlice';
import { getUserPhone } from 'utils/auth';
import useTwaApp from '../../hooks/useTwaApp';
import { useLogoutUser } from '../../services/rest/auth';
import theme from '../../theme';
import HeaderLogo from './HeaderLogo';
import { statice } from './data';
import styles, { MenuLink } from './styles';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, loginWithModal } = useLogin();
  const { headerMenu, profileMenu } = statice;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const { replace, push } = useRouter();
  const { isTwa } = useTwaApp();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = useLogoutUser();

  const handleExit = () => {
    logoutUser.mutate(undefined, {
      onSuccess: () => {
        dispatch(logout());
        handleClose();
        if (isTwa) {
          replace(`${ENV.APP_URL}/auth`);
        }
      },
    });
  };

  const renderMenuWithSubMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'white',
          },
        }}
      >
        <MenuItem>
          <MenuLink
            width="100%"
            href={`${ENV.APP_URL}/profile`}
            flexDirection={'column'}
          >
            <Box
              alignItems={'center'}
              justifyContent="space-between"
              display={'flex'}
              fontSize={'0.875rem'}
              fontWeight={'500'}
              width="100%"
            >
              <Box display="flex">
                <Person2Icon fontSize="small" />
                <span>کاربر کارنامه</span>{' '}
              </Box>
              <ArrowBackIosIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  minWidth: '12px',
                  minHeight: '12px',
                }}
              />
            </Box>
            <Box
              alignItems={'center'}
              justifyContent="start"
              width="100%"
              display={'flex'}
              color={'rgba(0,0,0,.32)'}
              marginTop={'4px'}
              marginLeft={'28px'}
            >
              {' '}
              {getUserPhone()}
            </Box>
          </MenuLink>
        </MenuItem>
        <Divider />
        {profileMenu.map((item) => (
          <MenuItem key={`profile-${item.id}-item`} sx={{ mb: '0.5rem' }}>
            <MenuLink minWidth="unset" href={item.link}>
              <Stack
                sx={{
                  width: '2.5rem',
                  height: '2.5rem',
                  backgroundColor: '#EBF8F1',
                  borderRadius: '0.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {item.icon}
              </Stack>
              <span>{item.title}</span>{' '}
            </MenuLink>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem>
          <MenuLink width="100%" onClick={handleExit}>
            <LogoutIcon fontSize="small" />
            <span>خروج</span>{' '}
          </MenuLink>
        </MenuItem>
      </Menu>
    );
  };

  const handleUserLogin = () => {
    if (isTwa) {
      replace(`${ENV.APP_URL}/auth`);
    } else {
      loginWithModal();
    }
  };

  const headerActions = useMemo(
    () => (
      <Stack direction={'row'} sx={styles.actionButtonWrapper} gap={1}>
        {isLoggedIn && (
          <Link
            href={`${ENV.APP_URL}/notifications`}
            id="notifications-icon"
            sx={{
              ...styles.actionButton,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NotificationsOutlinedIcon sx={styles.buttonIcons} />
          </Link>
        )}
        <Divider orientation="vertical" />
        <Button
          variant="text"
          onClick={() => {
            sendUserAction({
              service_name: 'guaranty',
              event_name: `guaranty-button-header`,
              event_type: 'click',
              event_metadata: {
                phoneNumber: getUserPhone() ?? '',
                env: process.env.APP_ENV,
              },
            });
            push('/contact-us');
          }}
          id="phone-icon"
          sx={{
            ...styles.actionButton,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
          }}
          aria-label="contact-us-button"
        >
          <PhoneInTalkOutlined sx={styles.buttonIcons} />
          <Box sx={styles.actionButtonText}>ارتباط با کارنامه</Box>
        </Button>
        <Divider orientation="vertical" />
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label="account button"
          variant="text"
          startIcon={
            isLoggedIn ? (
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    color: (t) => t.palette.success.main,
                    backgroundColor: (t) => t.palette.success.main,
                    marginRight: '-2px',
                    top: '5px',
                    left: '19px',
                    [theme.breakpoints.up('lg')]: {
                      left: '7px',
                    },
                  },
                }}
                badgeContent=" "
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                variant="dot"
              >
                <PersonOutlineOutlinedIcon sx={styles.buttonIcons} />
              </Badge>
            ) : (
              <PersonOutlineOutlinedIcon sx={styles.buttonIcons} />
            )
          }
          sx={styles.actionButton}
          onClick={!isLoggedIn ? () => handleUserLogin() : handleClick}
        >
          <Box sx={styles.actionButtonText}>
            {isLoggedIn ? 'حساب کاربری' : 'ورود / ثبت‌نام'}
          </Box>
        </Button>
        {isLoggedIn ? renderMenuWithSubMenu() : ''}
      </Stack>
    ),
    [isLoggedIn, open, isTwa], // eslint-disable-line
  );

  return (
    <Container sx={styles.headerContainer}>
      <Stack direction="row">
        <Box sx={styles.headerLogoWrapper}>
          <HeaderLogo href={ENV.LANDING_URL} />
        </Box>
        <Box sx={styles.menuWrapper}>
          <MenuList list={headerMenu} />
        </Box>
      </Stack>
      {headerActions}
    </Container>
  );
};

export default Header;
