import Cookies from 'universal-cookie';

export const FCM_TOKEN = 'fcmToken';
export const DEVICE_ID = 'deviceId';

export const getDeviceIdFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get(DEVICE_ID) as string;
};

export const setDeviceIdCookie = (deviceId: string) => {
  const cookies = new Cookies();
  const expires = new Date();

  expires.setMonth(expires.getMonth() + 1);
  cookies.set(DEVICE_ID, deviceId, { expires, path: '/' });
};

export const getFcmTokenFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get(FCM_TOKEN) as string;
};

export const setFcmTokenCookie = (fcmToken: string) => {
  const cookies = new Cookies();
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 1);
  cookies.set(FCM_TOKEN, fcmToken, { expires, path: '/' });
};
