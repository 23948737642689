import type { EmotionCache } from '@emotion/cache';
import { type DehydratedState } from '@tanstack/react-query';
import Layout from 'layout';
import App, { AppContext, AppProps } from 'next/app';

import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { parseCookies, setCookie } from 'nookies';
import AppThirdPartiesProvider from 'providers/AppThirdPartiesProvider';
import { ToastContainer } from 'react-toastify';

import Login from 'components/Login';
import { useComponentWillMountInClient } from 'hooks/useComponentWillMountInClient';
import AppClickTracerProvider from 'providers/AppClickTracerProvider';
import AppMuiThemeProvider from 'providers/AppMuiThemeProvider';
import AppQueryClientProvider from 'providers/AppQueryClientProvider';
import AppReduxProvider from 'providers/AppReduxProvider';
import store from 'states';
import { setGlobalUserIp } from 'states/globalSlice';
import { KARNAMEH_COOKIE_DOMAIN_VALUE } from 'utils/auth';
import { setSentryUserDataFromToken } from 'utils/setSentryUserData';
import { generateUUID } from 'utils/uuid';
import useTwaApp from '../hooks/useTwaApp';

import 'utils/overrideConsole';

import AppPushToken from 'providers/AppPushToken';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'theme/fontiran.css';
import 'theme/global.css';
import dynamic from 'next/dynamic';
// import ChangeRouteLoading from 'components/ChangeRouteLoading';

const ChangeRouteLoading = dynamic(
  () => import('components/ChangeRouteLoading'),
  { ssr: false },
);

export interface MyAppProps extends AppProps {
  rtlEmotionCache?: EmotionCache;
  pageProps: {
    dehydratedState?: DehydratedState;
  };
  viewerId?: string;
  clientIp: string;
}

const Img = styled.img``;

const MyApp = ({
  Component,
  pageProps,
  rtlEmotionCache,
  viewerId,
  clientIp,
}: MyAppProps) => {
  const { query } = useRouter();
  const { setAppReferrerSessionStorage, isTwa } = useTwaApp();

  useComponentWillMountInClient(() => {
    store.dispatch(setGlobalUserIp(clientIp));
    if (query.app_referrer !== 'twa' && !isTwa) {
      setAppReferrerSessionStorage('app');
    } else {
      setAppReferrerSessionStorage('twa');
    }

    setSentryUserDataFromToken();
  });

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    const currentScrollY = window.scrollY;

    return () => {
      window.scrollTo(0, currentScrollY);
    };
  }, []);

  const { events: routerEvent } = useRouter();
  /**
   * it will be true if the page route is changing
   */
  const [isRouteChanging, setIsRouteChanging] = useState(false);

  /**
   * changes the isRouteChanging value whenever the page route
   *  starts or ends changing
   */
  useEffect(() => {
    const startChangeRouteHandler = () => {
      setIsRouteChanging(true);
    };

    const endChangeRouteHandler = () => {
      setIsRouteChanging(false);
    };

    routerEvent.on('routeChangeStart', startChangeRouteHandler);
    routerEvent.on('routeChangeComplete', endChangeRouteHandler);

    return () => {
      routerEvent.off('routeChangeStart', startChangeRouteHandler);
      routerEvent.off('routeChangeComplete', endChangeRouteHandler);
    };
  }, [routerEvent]);

  return (
    <AppThirdPartiesProvider>
      <AppMuiThemeProvider rtlEmotionCache={rtlEmotionCache}>
        <AppReduxProvider>
          <AppQueryClientProvider state={pageProps?.dehydratedState}>
            <AppClickTracerProvider>
              <AppPushToken>
                <Layout>
                  <Component {...pageProps} />
                  <ToastContainer
                    style={{ fontSize: '0.8rem' }}
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    closeOnClick
                    pauseOnHover
                    draggable
                    theme="light"
                  />
                  <Img
                    className="yektanet-img"
                    src="https://biscotti.yektanet.com/pixel?id=karnameh"
                  />

                  <Img
                    className="mediaad-img"
                    src={`https://api.mediaad.org/v2/cookie/match?callback=https://usertrace.karnameh.com/user-trace/v1/yektanet/cookies?viewer_id=${viewerId}`}
                  />
                  <Login />
                  <ChangeRouteLoading open={isRouteChanging} />
                </Layout>
              </AppPushToken>
            </AppClickTracerProvider>
          </AppQueryClientProvider>
        </AppReduxProvider>
      </AppMuiThemeProvider>
    </AppThirdPartiesProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;

  const cookies = parseCookies(ctx);
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

  const viewerId = 'viewer_id' in cookies ? cookies.viewer_id : generateUUID();

  const clientIp = ctx.res?.getHeader('x-forwarded-for') as string;

  setCookie(ctx, 'viewer_id', viewerId, {
    path: '/',
    expires: aYearFromNow,
    domain: KARNAMEH_COOKIE_DOMAIN_VALUE,
    sameSite: 'lax',
  });

  return {
    ...appProps,
    viewerId,
    clientIp,
  };
};

export default MyApp;
