import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import EmptyCar from '../../../../assets/images/emptyCar.png';
import {
  usePriceEstimation,
  useUpdateLead,
} from '../../../../services/rest/car-exchange';
import { CarProfileDto } from '../../../../services/rest/car-exchange/carExchange';

import CreateIcon from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import { getUserDataFromToken, getUserPhone } from 'utils/auth';
import useFunnelContext from '../../../../hooks/useFunnelContext';
import { CAR_EXCHANGE_KEY, CarExchangeState, carStatus } from '../../configs';
import { ModeType } from './SelectCar';

const SelectOneCar = ({
  car,
  onChangeMode,
  mode = 'default',
  uuid,
}: {
  car: CarProfileDto;
  onChangeMode: (selectedMode: ModeType) => void;
  mode?: ModeType;
  uuid: string;
}) => {
  const { data } = usePriceEstimation({ id: car.id });
  const updateLead = useUpdateLead();
  const funnelData = useFunnelContext();

  const userData = getUserDataFromToken();
  const phoneNumber = getUserPhone();

  const updateLocalStorage = (Car: CarProfileDto, price: number) => {
    const carExchangeState: CarExchangeState = JSON.parse(
      localStorage.getItem(CAR_EXCHANGE_KEY) ?? '',
    );
    carExchangeState.car = JSON.stringify(Car);
    carExchangeState.price = price;
    localStorage.setItem(CAR_EXCHANGE_KEY, JSON.stringify(carExchangeState));
  };

  const getEstimateText = () => {
    if (!car.latest_mileage && !car.body_status)
      return 'تخمین قیمت این خودرو با فرض سالم‌ و کم‌کارکرد بودن خودرو محاسبه شده‌است.';
    let text = car.latest_mileage
      ? `${car.latest_mileage.mileage.toLocaleString()} کیلومتر، `
      : 'کم کارکرد، ';
    text += car.body_status
      ? carStatus.find((c) => c.id === car.body_status?.summarized_status)
          ?.label
      : 'سالم‌';
    return text;
  };

  const handleUpdateLead = () => {
    updateLead.mutate(
      {
        uuid: uuid,
        carprofile: Number(car.id),
        car_estimated_price: data?.today_price ?? null,
      },
      {
        onSuccess: () => {
          updateLocalStorage(car, data?.today_price ?? 0);
          if (!data?.today_price) funnelData?.changeStep('car-price');
          else funnelData?.changeStep('determining-budget');
        },
      },
    );
  };

  return (
    <Stack gap={4} sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>
        درخواست معاوضه شما برای خودروی زیر است:
      </Typography>
      <Stack
        gap={3}
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: { xs: 'center', md: 'start' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Stack width="12rem" height="7rem" sx={{ position: 'relative' }}>
          <Image
            src={car.primary_image ? car.primary_image.image : EmptyCar}
            alt="empty car image"
            layout="fill"
            objectFit="cover"
            style={{ borderRadius: '0.5rem' }}
          />
        </Stack>
        <Stack
          gap={3}
          height={{ md: '100%' }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: '0.5rem',
          }}
        >
          <Stack gap={1}>
            {car.name && (
              <Typography sx={{ textAlign: { xs: 'center', md: 'start' } }}>
                {car.name}
              </Typography>
            )}
            <Typography
              color={car.name ? 'iconDefault.main' : 'black'}
              sx={{
                textAlign: { xs: 'center', md: 'start' },
              }}
            >
              {car.brand_model_fa} {car.manufactured_year}
            </Typography>
          </Stack>

          {data && data.today_price && (
            <Typography
              sx={{
                textAlign: { xs: 'center', md: 'start' },
              }}
            >
              حدود قیمت: {data.today_price.toLocaleString()} (تومان)
            </Typography>
          )}
        </Stack>
      </Stack>

      {data && data.today_price && (
        <Stack gap={3}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              textAlign: { xs: 'center', md: 'start' },
              lineHeight: '1.5rem',
            }}
          >
            {getEstimateText()}
          </Typography>
          <Stack
            sx={{
              display: 'flex',
              alignItems: { xs: 'center', md: 'start' },
              width: '100%',
            }}
          >
            <Stack
              onClick={() => onChangeMode('edit')}
              gap={1}
              sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
            >
              <CreateIcon
                sx={{
                  width: '18px',
                  height: '18px',
                  color: (theme) => theme.palette.primary.main,
                }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                ویرایش وضعیت خودرو
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: mode === 'default' ? 'space-between' : 'end',
          width: '100%',
          flexShrink: 0,
          position: 'absolute',
          bottom: 0,
          pt: '1.5rem',
          borderTop: { md: '1px solid #EBEBEB' },
        }}
      >
        {mode === 'default' && (
          <Typography
            onClick={() => onChangeMode('addCar')}
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
              cursor: 'pointer',
            }}
          >
            افزودن خودروی جدید
          </Typography>
        )}
        <Button
          _istraceable="true"
          _traceservicename={'car-exchange'}
          _traceeventname={'car-exchange-select-car-submit'}
          _traceeventtype={'submit'}
          _traceeventmetadata={JSON.stringify({
            brand_model: car.brand_model_fa,
            manufactured_year: car.manufactured_year,
            price: data?.today_price ?? 0,
            ...userData,
            phoneNumber,
          })}
          onClick={handleUpdateLead}
          sx={{
            width: { xs: mode === 'default' ? '50%' : '100%', md: '12.5rem' },
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          تایید و ادامه
        </Button>
      </Box>
    </Stack>
  );
};

export default SelectOneCar;
