import {
  MutationFunction,
  QueryFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { apiClient } from 'services/clients';
import { VehicleType } from 'states/pages-slices/carInspectionSlice';
import { api2Endpoints, restEndpoints } from 'services/configs';
import { httpClient } from 'services/http-client';

export type FindCarsServiceNames =
  | 'concierge_sell'
  | 'concierge_buy'
  | 'inspection';

export interface Car {
  last_level: string;
  last_level_id: number;
  name: string;
  name_en: string;
  vehicle_type?: VehicleType;
  nationality?: 'iran' | string;
}

export interface CarConfig {
  min_kilometers?: number;
  max_kilometers?: number;
  min_price?: number;
  max_price?: number;
  min_manufacture_year?: number;
}

// #region Request: GET /find_cars/v1/fetch_cars, params: service_name

interface FindCarsV1FetchCarsParams {
  serviceName?: FindCarsServiceNames;
  vehicle_type?: VehicleType;
  manufacturer_type?: string;
}

interface FindCarsV1FetchCarsResponse {
  data: Car[];
}

export const findCarsV1FetchCars = (params: FindCarsV1FetchCarsParams) => {
  return apiClient<FindCarsV1FetchCarsResponse>({
    method: 'GET',
    url: restEndpoints.FETCH_CARS_V1,
    params: {
      service_name: params.serviceName,
      vehicle_type: params.vehicle_type,
      manufacturer_type: params.manufacturer_type,
    },
  });
};

export const findCarsV1FetchCarsKey = () => {
  return ['findCarsV1FetchCars', findCarsV1FetchCars];
};

export const useFindCarsV1FetchCars = (
  params: FindCarsV1FetchCarsParams,
  options?: Omit<
    UseQueryOptions<
      FindCarsV1FetchCarsResponse,
      unknown,
      FindCarsV1FetchCarsResponse,
      ReturnType<typeof findCarsV1FetchCarsKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = findCarsV1FetchCarsKey();
  return useQuery({
    ...options,
    queryFn: () => {
      return findCarsV1FetchCars(params).then((res) => res.data);
    },
    queryKey,
  });
};

// #endregion

// #region Request: GET /find_cars/v1/search, params: service_name, text

interface FindCarsV1SearchParams {
  serviceName?: FindCarsServiceNames;
  text: string;
  vehicle_type?: VehicleType;
  manufacturer_type?: string;
}

interface FindCarsV1SearchResponse {
  data: (Car & {
    concierge_sell_config: {
      min_manufacture_year: number;
      max_kilometers: number;
    };
  })[];
}

export const findCarsV1Search = (params: FindCarsV1SearchParams) => {
  return apiClient<FindCarsV1SearchResponse>({
    method: 'GET',
    url: restEndpoints.CAR_SEARCH,
    params: {
      service_name: params.serviceName,
      vehicle_type: params.vehicle_type,
      text: params.text,
      manufacturer_type: params.manufacturer_type,
    },
  }).then((res) => {
    return res.data;
  });
};

export const useFindCarsV1Search = (
  initialParams: Omit<FindCarsV1SearchParams, 'text'>,
  options?: UseMutationOptions<
    FindCarsV1SearchResponse,
    unknown,
    FindCarsV1SearchParams,
    unknown
  >,
) => {
  const mutationFn: MutationFunction<
    FindCarsV1SearchResponse,
    Omit<FindCarsV1SearchParams, 'serviceName'>
  > = (params) => {
    return findCarsV1Search({ ...initialParams, ...params });
  };
  return useMutation({
    mutationFn,
    ...options,
  });
};

// #endregion

// #region GET /cars/v1/-/get-assistant-buy-configs, params: last_level, last_level_id
interface GetBuyAssisstantCarConfigParams {
  levelId: string;
  lastLevelId: number;
}

export const getBuyAssisstantCarConfig = (
  params: GetBuyAssisstantCarConfigParams,
) => {
  return apiClient<CarConfig>({
    method: 'GET',
    params: {
      last_level: params.levelId,
      last_level_id: params.lastLevelId,
    },
    url: api2Endpoints.BUY_ASSISTANT_CONFIG,
  });
};

export const useGetBuyAssisstantCarConfig = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof getBuyAssisstantCarConfig>>,
    unknown,
    GetBuyAssisstantCarConfigParams,
    unknown
  >,
) => {
  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getBuyAssisstantCarConfig>>,
    GetBuyAssisstantCarConfigParams
  > = (params) => {
    return getBuyAssisstantCarConfig(params);
  };

  const mutationKey = ['getBuyAssisstantCarConfig', mutationFn];

  return useMutation({
    mutationFn,
    mutationKey,
    ...options,
  });
};

// #endregion

export const getCars = async (
  params: GetCarsParams,
  cacheOption?: {
    cache?: boolean;
    cacheTimeout?: number;
  },
) => {
  return httpClient<GetCarsResponse>({
    method: 'GET',
    url: restEndpoints.FIND_CARS_BRAND_MODELS,
    ...cacheOption,
    params,
  }).then((res) => {
    return res.data;
  });
};

export const getCarsKey = (params: GetCarsParams) => {
  return ['getCars', params.service_name, params.vehicle_type];
};

export const useGetCars = (
  params: GetCarsParams,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getCars>>>,
) => {
  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCars>>> = () => {
    return getCars(params);
  };

  const queryKey = getCarsKey(params);

  return useQuery({
    queryFn,
    queryKey,
    ...options,
  });
};

//#region carColors
export const getCarColors = () => {
  return apiClient<GetCarColorsResponse>({
    method: 'GET',
    url: api2Endpoints.CAR_COLORS,
  }).then((res) =>
    Object.entries(res.data).map(([key, value]) => ({ id: key, label: value })),
  );
};

export const carColorsKey = ['getCarColors'];

export const useGetCarColors = (
  options?: UseQueryOptions<
    unknown,
    unknown,
    Awaited<ReturnType<typeof getCarColors>>
  >,
) => {
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCarColors>>
  > = () => {
    return getCarColors();
  };

  return useQuery({
    queryFn,
    queryKey: carColorsKey,
    ...options,
  });
};
//#endregion
