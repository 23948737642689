import type { SeverityLevel } from '@sentry/nextjs';
import { sendClientConsoleLog } from 'services/rest/userLogging';
import Cookies from 'universal-cookie';
import { getUserPhone } from 'utils/auth';

var LOG_CONSOLE = console.log,
  INFO_CONSOLE = console.info,
  WARN_CONSOLE = console.warn;

function getClientInfo() {
  const cookie = new Cookies();
  const currentUserPhone = getUserPhone();
  const viewerId = (cookie.get('viewer_id') as string) || '';
  const currentUrl = location.href;
  return {
    currentUserPhone,
    viewerId,
    currentUrl,
  };
}

function sendLog(logData: { data: unknown[]; type: SeverityLevel }) {
  if (process.env.NODE_ENV === 'production')
    if (typeof window !== 'undefined') {
      const clientData = getClientInfo();
      if (logData.data.length > 0)
        return sendClientConsoleLog({
          viewer_id: clientData.viewerId,
          current_url: clientData.currentUrl,
          event_type: logData.type,
          app_referrer: 'web',
          event_metadata: {
            console_params: JSON.stringify(logData.data),
            current_user_phone: clientData.currentUserPhone,
          },
        });
    }
}

type ConsoleInsType =
  | typeof LOG_CONSOLE
  | typeof INFO_CONSOLE
  | typeof WARN_CONSOLE;

function overrideHandler(
  consoleIns: ConsoleInsType,
  type: SeverityLevel,
  args: unknown[],
) {
  const filteredArgs = args
    .filter((item) => {
      return item !== 'stdOut_off';
    })
    .filter((item) => item !== 'sendLog_off');
  if (!args.includes('sendLog_off'))
    sendLog({
      data: filteredArgs,
      type: type,
    });

  if (!args.includes('stdOut_off')) consoleIns.apply(console, filteredArgs);
}

function overrideConsole() {
  console.warn = (...args: unknown[]) =>
    overrideHandler(WARN_CONSOLE, 'warning', args);
  console.info = (...args: unknown[]) =>
    overrideHandler(INFO_CONSOLE, 'info', args);
  console.log = (...args: unknown[]) =>
    overrideHandler(LOG_CONSOLE, 'log', args);
}

overrideConsole();
