import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { sortList } from 'containers/buy-used-cars/config';

export interface PLPPageState {
  data: CarPostItem[] | null;
  currentFilters: PLPCurrentFilters;
  sort: string;
  page: number;
  search: string;
  shouldUpdate: boolean;
  autoUpdate: boolean;
  isMobileFilterOpen: boolean;
}

const initialPLPState: PLPPageState = {
  data: null,
  currentFilters: [],
  sort: sortList[0].id,
  page: -1,
  search: '',
  shouldUpdate: false,
  autoUpdate: false,
  isMobileFilterOpen: false,
};

const { actions, reducer } = createSlice({
  name: 'plp',
  initialState: initialPLPState,
  reducers: {
    setCurrentFilters: (state, action: PayloadAction<PLPCurrentFilters>) => {
      state.currentFilters = action.payload;
    },
    switchShouldUpdate: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdate = action.payload;
    },
    switchAutoUpdate: (state, action: PayloadAction<boolean>) => {
      state.autoUpdate = action.payload;
    },
    switchIsMobileFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileFilterOpen = action.payload;
    },
    setSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setData: (state, action: PayloadAction<PLPPageState['data']>) => {
      state.data = action.payload;
    },
    setSearch: (state, action: PayloadAction<PLPPageState['search']>) => {
      state.search = action.payload;
    },
    appendData: (state, action: PayloadAction<PLPPageState['data']>) => {
      if (Array.isArray(state.data) && Array.isArray(action.payload)) {
        action.payload.forEach((dataItem) => {
          (state.data as CarPostItem[]).push(dataItem);
        });
      } else {
        state.data = action.payload;
      }
    },
    setCurrentFiltersCheckbox: (
      state,
      action: PayloadAction<PLPCurrentFilterCheckbox>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) =>
          filterItem.type === action.payload.type &&
          filterItem.queryKey === action.payload.queryKey,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterCheckbox
        ).actives = action.payload.actives;
        state.shouldUpdate = true;
      }
    },
    setCurrentFilterPrice: (
      state,
      action: PayloadAction<PLPCurrentFilterPrice>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) =>
          filterItem.type === action.payload.type &&
          filterItem.minQueryKey === action.payload.minQueryKey &&
          filterItem.maxQueryKey === action.payload.maxQueryKey,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterPrice
        ).minPrice = action.payload.minPrice;
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterPrice
        ).maxPrice = action.payload.maxPrice;
        state.shouldUpdate = true;
      }
    },
    setCurrentFilterYear: (
      state,
      action: PayloadAction<PLPCurrentFilterYear>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) =>
          filterItem.type === action.payload.type &&
          filterItem.minQueryKey === action.payload.minQueryKey &&
          filterItem.maxQueryKey === action.payload.maxQueryKey,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterYear
        ).minYear = action.payload.minYear;
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterYear
        ).maxYear = action.payload.maxYear;
        state.shouldUpdate = true;
      }
    },
    setCurrentFilterUsage: (
      state,
      action: PayloadAction<PLPCurrentFilterUsage>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) =>
          filterItem.type === action.payload.type &&
          filterItem.queryKey === action.payload.queryKey,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterUsage
        ).maxUsage = action.payload.maxUsage;
        state.shouldUpdate = true;
      }
    },
    setCurrentFilterSwitch: (
      state,
      action: PayloadAction<PLPCurrentFilterSwitch>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) =>
          filterItem.type === action.payload.type &&
          filterItem.queryKey === action.payload.queryKey,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterSwitch
        ).checked = action.payload.checked;
        state.shouldUpdate = true;
      }
    },
    setCurrentFilterBrandModelTip: (
      state,
      action: PayloadAction<PLPCurrentFilterBrandModelTip>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) => filterItem.type === action.payload.type,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[
            indexOfFilterItem
          ] as PLPCurrentFilterBrandModelTip
        ).actives = action.payload.actives;
        state.shouldUpdate = true;
      }
    },
    setCurrentFilterCity: (
      state,
      action: PayloadAction<PLPCurrentFilterCity>,
    ) => {
      const indexOfFilterItem = state.currentFilters.findIndex(
        (filterItem) => filterItem.type === action.payload.type,
      );
      if (indexOfFilterItem !== -1) {
        (
          state.currentFilters[indexOfFilterItem] as PLPCurrentFilterCity
        ).actives = action.payload.actives;
        state.shouldUpdate = true;
      }
    },
  },
});

export const {
  setCurrentFilters: setPLPCurrentFilters,
  switchShouldUpdate: switchPLPShouldUpdate,
  switchAutoUpdate: switchPLPAutoUpdate,
  switchIsMobileFilterOpen: switchPLPIsMobileFilterOpen,
  setSort: setPLPSort,
  setCurrentFiltersCheckbox: setPLPCurrentFiltersCheckbox,
  setCurrentFilterPrice: setPLPCurrentFiltersPrice,
  setCurrentFilterYear: setPLPCurrentFiltersYear,
  setCurrentFilterUsage: setPLPCurrentFiltersUsage,
  setCurrentFilterSwitch: setPLPCurrentFiltersSwitch,
  setCurrentFilterBrandModelTip: setPLPCurrentFilterBrandModelTip,
  setCurrentFilterCity: setPLPCurrentFilterCity,
  setData: setPLPData,
  appendData: appendPLPData,
  setPage: setPLPPage,
  setSearch: setPLPSearch,
} = actions;

export { reducer as plpReducer };
