import { InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Layout from 'containers/car-services-order/components/layout';
import { useFormik } from 'formik';
import Image from 'next/image';
import { FC, useEffect, useState } from 'react';
import { getUserDataFromToken, getUserPhone } from 'utils/auth';
import NoResult from '../../../../../public/assets/images/no-result.png';
import useFunnelContext from '../../../../hooks/useFunnelContext';
import { useUpdateLead } from '../../../../services/rest/car-exchange';
import { CarProfileDto } from '../../../../services/rest/car-exchange/carExchange';
import { CAR_EXCHANGE_KEY, CarExchangeState } from '../../configs';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const CarPrice: FC<PropTypes> = ({ currentStep, totalStep }) => {
  const updateLead = useUpdateLead();
  const [uuid, setUuid] = useState<string | undefined>(undefined);
  const funnelData = useFunnelContext();
  const [carName, setCarName] = useState('');

  const carExchangeState: CarExchangeState = JSON.parse(
    localStorage.getItem(CAR_EXCHANGE_KEY) ?? '',
  );
  const carProfile = JSON.parse(carExchangeState.car || '') as CarProfileDto;
  const userData = getUserDataFromToken();
  const phoneNumber = getUserPhone();
  const updateLocalStorage = (price: number) => {
    carExchangeState.price = price;
    localStorage.setItem(CAR_EXCHANGE_KEY, JSON.stringify(carExchangeState));
  };

  const formik = useFormik({
    initialValues: {
      price: '',
    },
    onSubmit: (newValues) => {
      if (!uuid) return;
      updateLead.mutate(
        {
          uuid: uuid,
          car_estimated_price: Number(newValues.price),
        },
        {
          onSuccess: () => {
            updateLocalStorage(Number(newValues.price));
            funnelData?.changeStep('determining-budget');
          },
        },
      );
    },
  });

  useEffect(() => {
    const carExchangeDate: CarExchangeState = JSON.parse(
      localStorage.getItem(CAR_EXCHANGE_KEY) ?? '',
    );
    setUuid(carExchangeDate.uuid);
    if (carExchangeDate.car) {
      const car = JSON.parse(carExchangeDate.car) as CarProfileDto;
      setCarName(car.name ?? `${car.brand_model_fa} ${car.manufactured_year}`);
    }
  }, []);

  return (
    <Layout
      header={{
        title: 'تخمین قیمت',
        totalPage: totalStep,
        currentPage: currentStep,
      }}
      extraScroll={false}
      isReactiveToResize={true}
    >
      <Stack
        gap={3}
        sx={{ height: '100%', width: '100%', position: 'relative' }}
      >
        <Stack display={'flex'} alignItems={'center'} width={'100%'}>
          <Stack width={'5.5rem'} height={'5.5rem'}>
            <Image src={NoResult} alt="no result" />
          </Stack>
        </Stack>
        <Typography
          textAlign="center"
          sx={{ lineHeight: '2rem', mb: { md: '1rem' } }}
        >
          متاسفانه در حال حاضر قادر به محاسبه قیمت «{carName}» نیستیم، حدود قیمت
          مدنظر شما برای این خودرو چند است؟
        </Typography>
        <TextField
          label="حدود قیمت خودرو"
          type="text"
          size="small"
          color="secondary"
          placeholder="بنویسید ..."
          name="price"
          inputMode="numeric"
          value={
            formik.values.price !== ''
              ? Number(formik.values.price).toLocaleString()
              : ''
          }
          onChange={(e) =>
            formik.setFieldValue('price', e.target.value.replace(/\D/g, ''))
          }
          sx={{ width: '100%' }}
          autoComplete="off"
          InputProps={{
            inputMode: 'numeric',
            endAdornment: <InputAdornment position="end">تومان</InputAdornment>,
          }}
        />
        <Button
          _istraceable="true"
          _traceservicename={'car-exchange'}
          _traceeventname={'car-exchange-car-price-submit'}
          _traceeventtype={'submit'}
          _traceeventmetadata={JSON.stringify({
            price: formik.values.price,
            brand_model: carProfile?.brand_model_fa,
            manufactured_year: carProfile?.manufactured_year,
            ...userData,
            phoneNumber,
          })}
          disabled={formik.values.price === '' || !uuid}
          onClick={formik.submitForm}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            p: 0,
            width: { xs: '100%', md: 'auto' },
            minWidth: { md: '12.5rem' },
            height: '2.5rem',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          تایید و ادامه
        </Button>
      </Stack>
    </Layout>
  );
};

export default CarPrice;
