import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { LRUCache } from 'lru-cache';
import { GetServerSidePropsContext } from 'next';
import Cookies from 'universal-cookie';
import { removeToken } from 'utils/auth';
import { isTwaApp } from 'utils/sessionStorageManager';
import ENV from '../constants/environments';

// Initialize the LRU Cache (server-side only)
const isServer = typeof window === 'undefined';
const cache = isServer
  ? new LRUCache<string, any>({
      max: 1000, // Maximum number of cached items
    })
  : null;

// Decoding helper
const decode = (arr = []) => {
  const decoder = new TextDecoder();
  const encodedArray = Uint8Array.from(arr);

  return decoder.decode(encodedArray);
};

// Add custom headers based on cookies (client-side only)
if (typeof window !== 'undefined') {
  const cookie = new Cookies(document.cookie);
  const groupType = cookie.get('ab-test-token');
  axios.defaults.headers.common['x-ab-test-group-type'] = decode(groupType);
}

// Axios response interceptor to handle token removal
axios.interceptors.response.use((config) => {
  if (config.status === 403 || config.status === 401) removeToken();
  return config;
});

// Extend AxiosRequestConfig to include cache options
export interface CachedAxiosRequestConfig extends AxiosRequestConfig {
  cache?: boolean; // Optional cache flag, default is false
  cacheTimeout?: number; // Optional cache timeout (TTL) in milliseconds
}

// Axios request wrapper with optional caching and custom cache timeout
const createGatewayClient = () => {
  const baseURL = ENV.API_GW_BASE_URL;

  return async <Response>(
    configs: CachedAxiosRequestConfig,
    req?: GetServerSidePropsContext['req'],
  ): Promise<AxiosResponse<Response>> => {
    const headers = configs.headers || {};

    // Add user-platform header based on TWA
    if (!!configs.url && /^\/(fast|rest|trade)/.test(configs.url)) {
      headers['user-platform'] = isTwaApp() ? 'twa' : 'web';
    }

    // Add X-Real-Ip header when running on the server
    if (isServer && req) {
      headers['X-Real-Ip'] =
        req.headers['x-forwarded-for'] || req.socket.remoteAddress;
    }

    configs.headers = headers;
    configs.baseURL = baseURL;

    const cacheKey = `${configs.url}_${JSON.stringify(
      configs.params || {},
    )}_${JSON.stringify(configs.data || {})}`;
    const {
      cache: useCache = false,
      cacheTimeout = 1000 * 60 * 5, // Cache time-to-live: 5 minutes
    } = configs;

    // Check if the response exists in cache
    if (isServer && useCache && cache?.has(cacheKey)) {
      console.log('Serving from cache:', cacheKey);
      return {
        ...cache.get(cacheKey),
        fromCache: true,
      } as AxiosResponse<Response>;
    }

    try {
      // Perform the Axios request
      const response = await axios.request<Response>({ ...configs });

      // Store in cache if caching is enabled
      if (isServer && useCache) {
        cache?.set(cacheKey, response, { ttl: cacheTimeout });
      }

      return response;
    } catch (error) {
      // Handle errors (e.g., token removal or other error handling)
      throw error;
    }
  };
};

export const apiClient = createGatewayClient();

export type ErrorType<Error> = AxiosError<Error>;
