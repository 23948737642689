const CarHistoryIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.88 18.6893C8.67999 16.8093 8.35999 13.5593 10.1 11.2893C10.16 11.2093 10.22 11.1393 10.29 11.0593C11.82 9.2693 14.27 8.7293 16.35 9.5293V8.2093C16.35 8.0693 16.3 7.9393 16.2 7.8393L11.81 3.1993C11.71 3.0893 11.57 3.0293 11.42 3.0293H4.29999C3.16999 3.0293 2.23999 3.9493 2.23999 5.0893V18.8893C2.23999 20.0193 3.15999 20.9493 4.29999 20.9493C4.29999 20.9493 10.86 20.9393 14.3 20.9393C15.16 20.9393 15.89 20.4093 16.19 19.6593C14.43 20.2893 12.4 19.9893 10.88 18.6893ZM7.52999 17.7193C6.57999 17.7193 6.56999 17.7193 5.60999 17.7193C5.33999 17.7193 5.12999 17.5593 5.06999 17.3093C4.99999 17.0593 5.09999 16.7993 5.32999 16.6793C5.41999 16.6293 5.53999 16.5993 5.64999 16.5993C7.54999 16.5993 5.64999 16.5993 7.54999 16.5993C7.90999 16.5993 8.15999 16.8493 8.14999 17.1693C8.13999 17.4993 7.88999 17.7193 7.52999 17.7193ZM7.52999 14.8693C6.57999 14.8693 6.56999 14.8693 5.60999 14.8693C5.33999 14.8693 5.12999 14.7093 5.06999 14.4593C4.99999 14.2093 5.09999 13.9493 5.32999 13.8293C5.41999 13.7793 5.53999 13.7493 5.64999 13.7493C7.54999 13.7493 5.64999 13.7493 7.54999 13.7493C7.90999 13.7493 8.15999 13.9993 8.14999 14.3193C8.13999 14.6393 7.88999 14.8693 7.52999 14.8693ZM7.53999 11.7593H7.25999C6.56999 11.7593 6.45999 11.7593 5.60999 11.7593C5.33999 11.7593 5.12999 11.5993 5.06999 11.3493C4.99999 11.0993 5.09999 10.8393 5.32999 10.7293C5.41999 10.6793 5.53999 10.6493 5.64999 10.6493C7.54999 10.6493 5.64999 10.6393 7.54999 10.6493C7.90999 10.6493 8.15999 10.8993 8.14999 11.2193C8.13999 11.5393 7.89999 11.7493 7.53999 11.7593ZM5.60999 8.5893C5.33999 8.5893 5.12999 8.4293 5.06999 8.1793C4.99999 7.9293 5.09999 7.6693 5.32999 7.5493C5.41999 7.4993 5.53999 7.4693 5.64999 7.4693C7.54999 7.4693 9.44999 7.4693 11.35 7.4693C11.71 7.4693 11.96 7.7193 11.95 8.0393C11.94 8.3693 11.7 8.5893 11.33 8.5893H8.49999C7.52999 8.5893 6.56999 8.5893 5.60999 8.5893Z"
        fill="#00A754"
      />
      <path
        d="M21.4 19.0985L19.75 17.8085C19.51 17.5985 19.2 17.5285 18.91 17.5885L18.33 17.0885C19.57 15.1585 19.23 12.5685 17.44 11.0385C15.49 9.36853 12.56 9.59853 10.89 11.5485C10.83 11.6085 10.78 11.6785 10.73 11.7385C9.24003 13.6885 9.51003 16.4785 11.4 18.0985C13.19 19.6285 15.8 19.5585 17.51 18.0285L18.09 18.5285C18.08 18.8185 18.2 19.1185 18.44 19.3185L20.09 20.6085C20.51 20.9685 21.15 20.9185 21.51 20.4985C21.88 20.0885 21.83 19.4585 21.4 19.0985ZM17.28 15.0685C17.01 15.0685 16.78 14.8585 16.78 14.5785C16.76 13.2485 15.66 12.1885 14.33 12.2185C14.06 12.2185 13.83 12.0085 13.83 11.7285C13.83 11.4585 14.04 11.2285 14.32 11.2285C16.19 11.1985 17.74 12.6985 17.77 14.5685C17.78 14.8385 17.56 15.0685 17.28 15.0685Z"
        fill="#00A754"
      />
    </svg>
  );
};
export default CarHistoryIcon;
