import AffidavitIcon from 'components/icons/navigation/AffidavitIcon';
import BuyIcon from 'components/icons/navigation/BuyIcon';
import CarInspectionIcon from 'components/icons/navigation/CarInspectionIcon';
import CarPriceIcon from 'components/icons/navigation/CarPriceIcon';
import MenuIcon from 'components/icons/navigation/MenuIcon';
import MotorInspectionIcon from 'components/icons/navigation/MotorInspectionIcon';
import MyCarIcon from 'components/icons/navigation/MyCarIcon';
import SellIcon from 'components/icons/navigation/SellIcon';
import UsedCarIcon from 'components/icons/navigation/UsedCarIcon';
import ENV from 'constants/environments';

import CarHistoryIcon from 'components/icons/navigation/CarHistoryIcon';
import CarPostIcon from 'components/icons/navigation/CarPostIcon';
import {
  MenuListItemInterface,
  ProfileMenuInterface,
} from 'interfaces/menuList.interface';
import CarAdsIcon from 'components/icons/navigation/CarAdsIcon';
import CarExchangeIcon from 'components/icons/navigation/CarExchangeIcon';
import KarnamaIcon from 'components/icons/navigation/KarnamaIcon';
import LeasingIcon from 'components/icons/navigation/LeasingIcon';
import PriceHistoryIcon from 'components/icons/navigation/PriceHistoryIcon';
import ViolationIcon from 'components/icons/navigation/ViolationIcon';

interface StaticsInterface {
  headerMenu: Array<MenuListItemInterface>;
  profileMenu: Array<ProfileMenuInterface>;
}

export const karnamehServices: MenuListItemInterface[] = [
  {
    id: 13,
    title: 'کارشناسی خودرو',
    icon: CarInspectionIcon,
    link: '/car-inspection',
  },
  {
    id: 17,
    title: 'معاوضه خودرو',
    link: '/services/car-exchange',
    icon: CarExchangeIcon,
  },
  {
    id: 15,
    title: 'خرید قسطی خودرو',
    link: '/services/loan',
    icon: LeasingIcon,
  },
  {
    id: 12,
    title: 'فروش خودرو',
    icon: SellIcon,
    link: '/car-sell',
  },
  {
    id: 23,
    title: 'استعلام سوابق خودرو',
    icon: CarHistoryIcon,
    link: '/car-authentication',
  },
  {
    id: 18,
    title: 'خرید قسطی موتور',
    link: '/services/motorcycle-loan',
    icon: LeasingIcon,
  },
  {
    id: 19,
    title: 'استعلام خلافی',
    icon: ViolationIcon,
    link: '/services/car-violation',
  },
  {
    id: 11,
    title: 'خرید خودرو',
    icon: BuyIcon,
    link: '/buy-used-cars',
  },
  {
    id: 16,
    title: 'قیمت معاملات اخیر',
    link: '/services/trade-price-report/',
    icon: PriceHistoryIcon,
  },
  {
    id: 14,
    title: 'مبایعه نامه',
    icon: AffidavitIcon,
    link: '/affidavit',
  },
  {
    id: 20,
    title: 'تخمین قیمت کارکرده',
    link: '/car-price/used-car',
    icon: UsedCarIcon,
  },
  {
    id: 21,
    title: 'کارشناسی موتور',
    icon: MotorInspectionIcon,
    link: '/motor-inspection',
  },
  {
    id: 22,
    title: 'کارنما',
    icon: KarnamaIcon,
    link: '/karnama',
  },
];

export const statice: StaticsInterface = {
  headerMenu: [
    {
      id: 1,
      title: 'خدمات',
      link: '/car-inspection',
      icon: MenuIcon,
      subMenu: karnamehServices,
      noMobile: true,
      prefetch: false,
    },
    {
      id: 8,
      title: 'خودروی من',
      link: `${ENV.APP_URL}/car-profile`,
      icon: MyCarIcon,
      noMobile: true,
    },
    {
      id: 2,
      title: 'آگهی های خودرو',
      link: '/buy-used-cars',
      icon: CarAdsIcon,
      noMobile: true,
      prefetch: true,
    },
    {
      id: 3,
      title: 'قیمت روز',
      link: '/car-price',
      icon: CarPriceIcon,
      noMobile: true,
      prefetch: true,
    },
  ],
  profileMenu: [
    {
      id: '1',
      link: `${ENV.APP_URL}/profile/services/orders?name=کارشناسی&type=inspection`,
      icon: <CarInspectionIcon type="navigation" />,
      title: 'سوابق درخواست کارشناسی خودرو',
    },
    {
      id: '2',
      link: `${ENV.APP_URL}/profile/car-posts`,
      icon: <CarPostIcon />,
      title: 'سوابق آگهی خودرو',
    },
    {
      id: '3',
      link: `${ENV.APP_URL}/profile/services/orders?name=دستیار%20معاملات&type=trade&serviceUrl=https://karnameh.com/user-profile/sell/details/`,
      icon: <SellIcon type="navigation" />,
      title: 'سوابق درخواست معاملات',
    },
    {
      id: '4',
      link: `${ENV.APP_URL}/profile/services/orders?name=خرید%20قسطی&serviceUrl=https://karnameh.com/user-profile/leasing/details/&type=leasing`,
      icon: <LeasingIcon type="navigation" />,
      title: 'سوابق درخواست خرید اقساطی',
    },
    {
      id: '5',
      link: `${ENV.APP_URL}/profile/services/orders?name=گزارش%20قیمت&serviceUrl=https://karnameh.com/price-report/&type=pricing-report`,
      icon: <PriceHistoryIcon type="navigation" />,
      title: 'سوابق درخواست قیمت معاملات اخیر',
    },
  ],
};
