import React, { PropsWithChildren, useEffect } from 'react';
import { usePushToken } from 'services/rest/boogh';
import { useRouter } from 'next/router';
import {
  DEVICE_ID,
  FCM_TOKEN,
  getDeviceIdFromCookie,
  getFcmTokenFromCookie,
  setDeviceIdCookie,
  setFcmTokenCookie,
} from 'utils/cookieManager';
import { isUserLoggedIn } from 'utils/auth';

const AppPushToken = ({ children }: PropsWithChildren<unknown>) => {
  const { mutate } = usePushToken();
  const router = useRouter();
  useEffect(() => {
    const fcmTokenParam = router.query[FCM_TOKEN] as string;
    const deviceIdParam = router.query[DEVICE_ID] as string;

    if (fcmTokenParam && deviceIdParam) {
      setDeviceIdCookie(deviceIdParam);
      setFcmTokenCookie(fcmTokenParam);
    }

    if (isUserLoggedIn()) {
      const deviceId = getDeviceIdFromCookie() ?? '';
      const token = getFcmTokenFromCookie() ?? '';
      if (deviceId && token) {
        mutate({
          data: {
            app_id: 'M',
            device_id: deviceId,
            token,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate, router.query]);

  return <>{children}</>;
};

export default AppPushToken;
